import React, { useState } from "react";
import Select from "react-tailwindcss-select";

function SelectSeries() {
    const options = [
      { value: "1000km", label: "1000km" },
      { value: "2000km", label: "2000km" },
      { value: "3000km", label: "3000km" },
      { value: "4000km", label: "4000km" },
    ];

  const [series, setSeries] = useState("");

  const handleChange = (value) => {
    console.log("value:", value);
    setSeries(value);
  };
  
  return (
    <>
  <div className="flex items-center justify-between">
  {/* LEFT */}
  <div className="flex items-center">
    {/* TITLE */}
    <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5">
      Maintenance
    </h3>
    {/* SELECT */}
    <div className="ml-4 min-w-[235px] h-[45px] bg-black-500 select">
      <Select
        value={series}
        onChange={handleChange}
        options={options}
        placeholder=""
        classNames={{
          menuButton: ({ isDisabled }) =>
            `flex w-full min-h-[45px] text-f_18_l_23 text-white border-[0.5px] border-white-100 rounded-[10px] transition-all duration-300 focus:ring-0 focus:outline-none ${
              isDisabled
                ? "bg-gray-200"
                : "bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
            }`,
          menu: "absolute z-10 w-full bg-black-500 shadow-lg border rounded-[10px] py-1 mt-1.5 text-f_18_l_23",
          listItem: ({ isSelected }) =>
            `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
              isSelected
                ? `text-white-100 bg-blue-500`
                : `text-white-100 hover:bg-blue-100 hover:text-blue-500`
            }`,
          tagItem: () =>
            "bg-black-600 text-white-100 text-[12px] border rounded-sm flex space-x-1 pl-1",
          tagItemText: () => "text-transparent",
        }}
      />
    </div>
  </div>
  {/* RIGHT */}
  <div className="pl-12 xl:pl-24">
    {/* Content for the right side */}
  </div>
</div>



    </>
  );
}

export default SelectSeries;
