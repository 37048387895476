import React, { useState, useEffect } from "react";
import Select from "react-tailwindcss-select";
import axios from "axios";

function SelectService() {
  const [mileages, setMileages] = useState([]);
  const [selectedMileage, setSelectedMileage] = useState(null);
  const [mileageOptions, setMileageOptions] = useState([]);


  useEffect(() => {
    // Fetch data from the API endpoint
    axios.get(`${process.env.REACT_APP_API_URL}/getallmileages/`)
      .then(response => {
        // Transform the API response data into an array of options
        const options = response.data.map(mileage => ({
          value: mileage.id, // Assuming you want to use the id as the value
          label: mileage.Mileage
        }));
        // Set the options in the state
        setMileageOptions(options);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []); // Empty dependency array ensures this effect runs only once on component mount
  const handleChange = (value) => {
    console.log("Selected mileage:", value);
    setSelectedMileage(value);
  };
 

  return (
    <div className="flex items-center justify-between">
      {/* LEFT */}
      <div className="flex items-center">
        {/* TITLE */}
        <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold ">
          The current mileage is approx
        </h3>
        {/* SELECT */}
        <div className="ml-4 min-w-[235px] h-[45px] bg-black-500 select">
          <Select
            value={selectedMileage}
            onChange={handleChange}
            options={mileageOptions}
            placeholder=""
            classNames={{
              // classNames for select styles
            }}
          />
        </div>
      </div>
      {/* RIGHT */}
      <div className="pl-12 xl:pl-24">
        {/* Content for the right side */}
      </div>
    </div>
  );
}

export default SelectService;
