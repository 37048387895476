import * as types from "./types";

const actions = {};

actions.showLoader = () => {
  return {
    type: types.SHOW_LOADER,
  };
};

actions.hideLoader = () => {
  return {
    type: types.HIDE_LOADER,
  };
};

export { actions };
