import React from 'react';
import us from "../../images/us.svg";
import et from "../../images/et.svg";
import fn from "../../images/fn.svg";
import lv from "../../images/lv.svg";

const getActiveIcon = (language) => {
  switch (language) {
    case 'EN':
      return us;
    case 'ET':
      return et;
    case 'LV':
      return lv;
    case 'FN':
      return fn;
    default:
      return us;
  }
};

const MobileLanguageSwitcher = ({ handleLanguageChange, activeLanguage }) => {
  return (
    <div className="flex flex-row md:hidden justify-between pt-[35px] p-[1.5rem] ">
      {['EN', 'ET', 'LV', 'FN'].map((language, index) => (
        <button
          key={language}
          className={`selectable-button ${index === 0 ? 'mr-2' : ''}`}
          onClick={() => handleLanguageChange(language)}
          data-language={language}
          style={{ marginBottom: '0', paddingBottom: '0' }}
        >
          <img
            src={getActiveIcon(language)}
            alt="Language Icon"
            style={{ width: '50px', opacity: language !== activeLanguage ? 0.5 : 1 }}
          />
        </button>
      ))}
    </div>
  );
};

export default MobileLanguageSwitcher;
