import { takeEvery, call, put } from "redux-saga/effects";
import { SIGNIN_REQUEST, VINN_REQUEST, SIGNUP_REQUEST } from "../actions/types";
import { signIn, vinnIn, signUp } from "../../api/users";
import { userActions, notificationActions, vinnActions, loaderActions } from "../actions";
import { toast } from "react-toastify";
import axios from "axios";
import { delay } from "redux-saga/effects";

function* signUpRequest(action) {
  try {
    yield put(loaderActions.showLoader());
    const user = yield call(signUp, action.payload.user);
    const { data } = user;
    
    // Save token and navigate to the home page
    localStorage.setItem("token", data.info);
    yield put(userActions.signUpSuccess(data.info));
    action.payload.navigate("/");
  } catch (error) {
    toast.error(`${error?.response?.data?.errors?.non_field_errors}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    yield put(notificationActions.addNotification(error.response.data));
    yield put(userActions.signUpSuccess({}));
  } finally {
    yield put(loaderActions.hideLoader());
  }
}

function* signInRequest(action) {
  try {
    yield put(loaderActions.showLoader());

    // Get the user's public IP address
    const ipResponse = yield call(axios.get, "https://api.ipify.org?format=json");
    const userIp = ipResponse.data.ip;
    
    // Delay for 500ms (optional)
    yield delay(500);

    // Sign in the user
    const user = yield call(signIn, action.payload.user);
    const { data } = user;

    // Check for IP access restrictions
    const ipAccessList = data?.profile?.ip_access ? data.profile.ip_access.split(',').map(ip => ip.trim()) : [];
    
    if (ipAccessList.length > 0 && !ipAccessList.includes(userIp)) {
      console.log("IP mismatch: ", userIp);
      toast.error("IP address mismatch", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    // If there are no restrictions or IP matches, proceed to save user data
    localStorage.setItem("token", data.token);
    localStorage.setItem("profile_image", data?.profile?.image);
    localStorage.setItem("profile", data?.profile?.name);
    localStorage.setItem("profile_id", data?.profile?.id);
    localStorage.setItem("profile_email", data?.profile?.email);
    localStorage.setItem("show_accessories", data?.profile?.show_accessories);
    localStorage.setItem("company_name", data?.profile?.company?.company_name);
    localStorage.setItem("company_id", data?.profile?.company?.id);

    console.log("IP allowed or no restrictions: ", userIp);
    
    // Navigate to the dashboard
    yield put(loaderActions.hideLoader());
    action.payload.navigate("/dashboard");
    window.location.reload();
    
    yield put(userActions.signInSuccess(data.profile));
  } catch (error) {
    console.error("Error during sign in: ", error);
    toast.error(`${error?.response?.data?.errors?.non_field_errors}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    yield put(notificationActions.addNotification(error.response.data));
    yield put(userActions.signInSuccess({}));
  } finally {
    yield put(loaderActions.hideLoader());
  }
}

function* vinnInRequest(action) {
  try {
    yield put(loaderActions.showLoader());
    
    const number = yield call(vinnIn, action.payload.number?.numb);
    localStorage.setItem("vinnData", JSON.stringify(number));
    console.log("VIN data received: ", number);
    
    // Navigate to vehicle data page
    action.payload.navigate("/vehicleData");
    window.location.reload();
    
    yield put(vinnActions.vinInSuccess(number.data));
  } catch (error) {
    toast.error(`${error?.response?.data?.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    yield put(notificationActions.addNotification(error?.response?.data));
    yield put(vinnActions.vinInSuccess({}));
  } finally {
    yield put(loaderActions.hideLoader());
  }
}

function* userWatcher() {
  yield takeEvery(SIGNUP_REQUEST, signUpRequest);
  yield takeEvery(SIGNIN_REQUEST, signInRequest);
  yield takeEvery(VINN_REQUEST, vinnInRequest);
}

export { userWatcher };
