import React, { useEffect, useState } from "react";
import logo from "../images/logo_big.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [captchaScore, setCaptchaScore] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleCaptchaExecution = async () => {
    if (!executeRecaptcha) return;

    try {
      // Execute reCAPTCHA and get the token (this also gives us the score)
      const token = await executeRecaptcha("forgot_password");
      setCaptchaScore(token); // Store the token/score for backend validation
    } catch (error) {
      toast.error("reCAPTCHA execution failed.");
      console.error("reCAPTCHA error:", error);
    }
  };

  useEffect(() => {
    // Trigger reCAPTCHA execution as soon as the component is mounted
    handleCaptchaExecution();
  }, [executeRecaptcha]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Email is required.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!captchaScore) {
      toast.error("Please verify you're not a robot.");
      return;
    }

    try {
      const redirect_url = window.location.origin;

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/request-reset-email/`,
        {
          email,
          redirect_url,
          captcha_token: captchaScore,
        }
      );
      toast.success(
        "You will receive a Reset Password Link, please check your email."
      );
    } catch (error) {
      if (error.response && error.response.data) {
        // If there's a response from the server with an error message, display it
        toast.error(
          error.response.data.failed ||
            "Something went wrong while sending Reset Password Link."
        );
      } else {
        // If there's no specific error message, show a generic error message
        toast.error("Something went wrong while sending Reset Password Link.");
      }
      console.error("Forgot Password API Error:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col w-full justify-center items-center px-[26px] md:px-[6.25rem] py-[5.625rem] md:py-[6.625rem]">
        <div className="max-w-[77.5rem] mx-auto w-full bg-black-200 flex flex-col items-center justify-center py-[4.8125rem] md:pt-[10.8125rem] md:pb-[11.875rem] rounded-2xl md:rounded-[25px]">
          {/* LOGO */}
          <div className="w-[226px] md:w-[400px] xl:w-auto mb-14 md:mb-[68px]">
            <img src={logo} alt="logo" />
          </div>

          {/* FORM */}
          <form className="w-full max-w-[22.1875rem] flex flex-col px-[26px] md:px-0 space-y-[30px]">
            {/* EMAIL INPUT */}
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-[53px] rounded-[10px] border border-white bg-transparent text-center text-white text-f_16_l_20 md:text-f_20_l_25 font-supremeMedium px-3 placeholder:text-white"
              placeholder="Email"
              type="email"
            />

            <button
              onClick={handleSubmit}
              className="bg-gradient w-full h-[53px] text-f_19_l_24 md:text-f_22_l_28 font-supremeMedium rounded-[10px]"
            >
              Get Reset Password Link
            </button>

            {/* FORGOT PASSWORD LINK */}
            <div className="text-center">
              <Link to="/" className="text-white text-sm hover:text-gray-300">
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

const ForgotPasswordWithReCaptcha = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <ForgotPassword />
    </GoogleReCaptchaProvider>
  );
};

export default ForgotPasswordWithReCaptcha;
