
// export default LanguageSwitcher;
import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import i18n from "../../i18n"; // Make sure the path to your i18n configuration is correct

// Import flag images
import est from "../../images/est.svg";
import fin from "../../images/fin.svg";
import livin from "../../images/lva.svg";
import eng from "../../images/usa.svg";
import axios from "axios";

const LanguageSwitcher = () => {
  const countries = [
    { value: "EN", label: "English", flag: eng },
    { value: "ET", label: "Estonian", flag: est },
    { value: "FN", label: "Finnish", flag: fin },
    { value: "LV", label: "Latvian", flag: livin },
  ];

  // Initialize state with the value from localStorage if it exists, otherwise default to initial language from i18n
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    localStorage.setItem("selectedLanguage", JSON.stringify(selectedOption));
    // Change the application language
    i18n.changeLanguage(selectedOption.value);
  }, [selectedOption]);

  useEffect(() => {
    const fetchUserLanguage = async () => {
      const profile_id = localStorage.getItem("profile_id");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/user/profile/${profile_id}/`
        );
        const userLang = response.data.language.toUpperCase();

        const foundCountry = countries.find((c) => c.value === userLang);

        localStorage.setItem(
          "selectedLanguage",
          JSON.stringify(
            foundCountry ||
              countries.find((c) => c.value === (i18n.language || "EN"))
          )
        );
        // Change the application language
        i18n.changeLanguage(userLang || "EN");

        setSelectedOption(foundCountry);
      } catch (error) {
        console.error("Error while getting user language from backend:", error);
        setSelectedOption(countries[0]); // Fallback to "EN" in case of error
      }
    };

    fetchUserLanguage();
  }, []);

  const handleLanguageChange = (selectedOption) => {
    console.log(
      "====================",
      localStorage.getItem("selectedLanguage")
    );
    setSelectedOption(selectedOption);

    const postUpdateUserLanguage = async () => {
      const profile_id = localStorage.getItem("profile_id");
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/user/user/profile/update-language/${profile_id}/`,
          {
            language: selectedOption.value.toUpperCase(),
          }
        );
      } catch (error) {
        console.error("Error while updating user language on backend:", error);
      }
    };

    postUpdateUserLanguage();
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "white", // Text color
      padding: 20,
      display: "flex",
      alignItems: "center",
      //   width: '100%',
      background: state.isSelected ? "#009bb4" : "#0f0f0f", // Background color for options
      ":hover": {
        background: "#007B8F", // Background color on hover
        color: "white",
      },
    }),
    control: (provided, state) => ({
      ...provided,
      width: 120,
      border: state.isSelected ? "none" : "none",
      backgroundColor: "#0f0f0f",
      boxShadow: "none",
      "&:hover": {
        borderColor: "none",
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#0f0f0f",
    }),
    menu: (provided) => ({
      ...provided,
      // Adjust background, border, margin, etc., as needed
      backgroundColor: "black",
      border: "none",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)", // Example of adding shadow
      marginTop: 0, // Remove any unwanted space above the menu
      marginBottom: 0, // Remove any unwanted space below the menu
    }),

    // Style for the menu list (container for options)
    menuList: (provided) => ({
      ...provided,
      // Adjust padding, etc., as needed
      paddingTop: 0, // Remove padding at the top of the menu list
      paddingBottom: 0, // Remove padding at the bottom of the menu list
    }),
  };

  const formatOptionLabel = (option, { context }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={option.flag}
        alt=""
        style={{ width: "25px", marginRight: "10px" }}
      />
      {context === "value" ? option.value : option.label}
    </div>
  );

  return (
    <div className="p-4" style={{ backgroundColor: "#0f0f0f" }}>
      <Select
        value={selectedOption}
        onChange={handleLanguageChange}
        options={countries}
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
        components={makeAnimated()}
      />
    </div>
  );
};

export default LanguageSwitcher;
