import {React,useState }from "react";
import ThemeSwither from "./ThemeSwither";
import bell_icon from "../../images/bell_icon.svg";
import profile_pic from "../../images/profile_pic.png";
import hamburger_icon from "../../images/hamburger_icon.svg";
import logo from "../../images/logo.svg";
import LanguageDropdown from "../Common/languagedropdown"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Header(props) {
  const image_url = `${localStorage.getItem('profile_image')}`
  const {t}=useTranslation();
  function getFirstWord(sentence) {
    // Split the sentence into an array of words
    const words = sentence.split(' ');

    // Return the first word (element at index 0)
    return words[0];
}
const username=localStorage.getItem("profile")
const permis=localStorage.getItem("show_accessories")
const navigate = useNavigate();
const firstWord = getFirstWord(username);
async function logout_function() {
  const userEmail = localStorage.getItem("profile_email");
  const vinnData = JSON.parse(localStorage.getItem("vinnData"));

  if (vinnData && vinnData.data && vinnData.data.vin_code) {
    const vinCode = vinnData.data.vin_code;
    await fetch(
    `${process.env.REACT_APP_API_URL}/vehicles/logout_vehicle/${userEmail}/?Vehicle_servicing=${vinCode}`
  );
}
}
  
    const [isOpen, setIsOpen] = useState(false);
  
    const handleLogout = () => {
      logout_function()
      localStorage.removeItem("vinnData");


      localStorage.removeItem("show_accessories");
      localStorage.removeItem("token");
      localStorage.removeItem("profile_email");
      window.location.reload();
    };
    const handleLogout2 = () => {
      navigate('/changepassword')
    };
  return (
    <>
      <div className="bg-black-300 px-6 md:pl-[16.25rem] xl:pl-[21.125rem] md:pr-9 xl:pr-12 w-full h-[62px] md:h-[6.5625rem]  fixed top-0" style={{zIndex:1}}>
        <div className="relative h-full flex items-center justify-between  md:justify-end">
          {/* HAMBURGER ICON */}
          <button className="md:hidden" onClick={()=> props.setOpenSidebar(true)}>
            <img src={hamburger_icon} alt="hamburger-icon" />
          </button>

          {/* LOGO */}
          <div className="md:hidden absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="w-[119px]">
              <img src={logo} alt="logo" />
            </div>
          </div>

          {/* THEME SWITCHER */}
           <div className="hidden md:block">
            <div className="mr-[34px] flex items-center">
              {/* <ThemeSwither /> */}



              <LanguageDropdown/>
            </div>
          </div> 

          {/* NOTIFICATIONS */}
          <div className="mr-10 hidden md:block">
            <img src={bell_icon} alt="bell-icon" />
          </div>

          {/* PROFILE */}
          <div className="relative">
      <div
        className="md:pl-9 md:border-l md:border-offWhite flex items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="w-7 h-7 md:w-11 md:h-11 rounded-full mr-3">
          <img
            className="w-full h-full rounded-full object-cover"
            src={image_url}
            alt="user-profile-pic"
          />
        </div>
        <span className="text-f_12_l_15 xl:text-f_16_l_20">
        {firstWord}
          
        </span>
      </div>
      {isOpen && (
        <div className="absolute right-0 mt-2 py-2 w-48 bg-main border border-gray-200 rounded shadow">
          <button
            className="block px-4 py-2 text-sm bg-gradient-text  w-full text-left"
            onClick={handleLogout}
          >
            Logout
          </button>
          <button
            className="block px-4 py-2 text-sm bg-gradient-text  w-full text-left"
            onClick={handleLogout2}
          >
            Reset Password
          </button>
        </div>
      )}
    </div>
          {/* <div className="md:pl-9 md:border-l md:border-offWhite flex items-center" onClick={
            ()=>{
              localStorage.removeItem("vinnData");
              localStorage.removeItem("token");
              window.location.reload();
          }}>
            <div className="w-7 h-7 md:w-11 md:h-11 rounded-full mr-3">
              <img
                className="w-full h-full rounded-full object-cover"
                src={image_url}
                alt="user-profile-pic"
              />
            </div>
            <span className="text-f_12_l_15 xl:text-f_16_l_20">{localStorage.getItem('profile')}</span>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Header;
