import React, {
    useRef,
    useState,
    useEffect,
    createContext,
    useContext,
  } from "react"
  import Sidebar from "../components/Common/Sidebar"
  import Header from "../components/Common/Header"
  import bike_img from "../images/bike_img.png"
  import placeholder_img from "../images/camera_img.svg"
  import SurveyForm from "../components/Service/Surveyform"
  import { useNavigate } from "react-router-dom"
  import axios from "axios"
  import { ToastContainer, toast } from 'react-toastify';
  import { useTranslation } from "react-i18next";
//   const navigate = useNavigate();
  
//   React.useEffect(() => {
//     // console.log("data=--_>> ",localStorage.getItem("vinnData"))
//     if(!localStorage.getItem("vinnData")){
//       navigate("/vinn")
//     }
//     else{
//       getApiData();
//     }
  
//   }, [])
  const data = localStorage.getItem("vinnData");
  const parsed_data = JSON.parse(data);
function Bulletin() {
    const navigate = useNavigate();
    const {t}=useTranslation();
    const [openSidebar, setOpenSidebar] = useState(false);
    const data = localStorage.getItem("vinnData")
    const bulletindata = localStorage.getItem("bulletinData")
    const bulletinnewdata = JSON.parse(bulletindata);
    const parsed_bulletin_data = JSON.parse(bulletindata)
    const db_bulletin_data = parsed_bulletin_data?.data
    console.log("ccvxvcx",bulletinnewdata);
    const firstBulletinid = bulletinnewdata[0]?.id;
    
    
    console.log("ccvxvcx4444444444",bulletinnewdata[0]?.pdf_instructions);
    const profile_id = localStorage.getItem("profile_id")
    const parsed_data = JSON.parse(data)
    const db_data = parsed_data?.data
    const product_id = db_data?.id
    const dateStr = db_data?.date_of_manufacture;
    const dateObj = new Date(dateStr);
    const year = dateObj.getFullYear();
    // console.log("db_data+++++>> ",db_data)
    const image_url = `${db_data?.image}`
    const pdf_url = `${process.env.REACT_APP_API_URL}/${bulletinnewdata[0]?.pdf_instructions}`
    // const [openSidebar, setOpenSidebar] = useState(false)
    const [comment, setComment] = useState("")
    const [selectedFile1, setSelectedFile1] = useState(null)
    const [selectedFile2, setSelectedFile2] = useState(null)
    const [selectedFile3, setSelectedFile3] = useState(null)
    const [selectedFile4, setSelectedFile4] = useState(null)
    const [files, setFiles] = useState([null, null, null, null])
    const [elapsedTime, setElapsedTime] = useState(0)
    const [selectedFile5, setSelectedFile5] = useState(null)
    const timers = useRef([])
    const [services, setServices] = useState([])
    const [fillvalue, setfillValue] = useState({})
    const [valvalue, setvalValue] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const currentItem = services[currentIndex]
    ///////
    const [isYesChecked, setIsYesChecked] = useState({})
    const [isChecked, setIsChecked] = useState({})
    const [textValue, setTextValue] = useState({})
    const [selectedFile6, setSelectedFile6] = useState(null)
    const timerRef = useRef([])
    const [timer, setTimer] = useState({})
    const [disabledIndices, setDisabledIndices] = useState([])
    const [timerValue, setTimerValue] = useState(0)
    const [timerRunning, setTimerRunning] = useState(false)
    const [dataPost,setDataPost] = useState(
      {
        // maintanence_id: 3,
        bulletins_id: 3,
        bulletins_mechanical_note: "",
        
        
        
       
       
    }
    )
    const handleImageClick2 = (ref) => {
      console.log("image picker", ref);
  
      // Check if the device is a mobile device
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
      if (isMobile) {
          // Mobile device - Ask the user for their choice
          if (window.confirm("Do you want to take a new photo? Click 'OK' for camera, 'Cancel' for gallery.")) {
              // User chooses to take a new photo
              ref.current.setAttribute('capture', 'environment');
          } else {
              // User chooses to pick an existing photo
              ref.current.removeAttribute('capture');
          }
      } else {
          // Non-mobile device - Standard file input behavior
          ref.current.removeAttribute('capture');
      }
  
      // Trigger the file input
      ref.current.click();
  }
    const fileInputRef1 = useRef(null)
    const fileInputRef2 = useRef(null)
    const fileInputRef3 = useRef(null)
    const fileInputRef4 = useRef(null)
    const fileInputRef6 = useRef(null)
    const fileInputRef5 = useRef(null)
  
    const handleImageClick = (ref) => {
      console.log("image pciker ", ref)
      ref.current.click()
    }
  
    const handleFileSelect1 = (event) => {
      setSelectedFile1(URL.createObjectURL(event.target.files[0]))
      files[0] = event.target.files[0]
      setFiles([...files])
    }
  
    const handleFileSelect2 = (event) => {
      setSelectedFile2(URL.createObjectURL(event.target.files[0]))
      files[1] = event.target.files[0]
      setFiles([...files])
    }
    const handleFileSelect6 = (event) => {
        setSelectedFile6(URL.createObjectURL(event.target.files[0]))
    
        setDataPost({
          ...dataPost,
          video:event.target.files[0]
        })
     
      }
    // const handleFileSelect3 = (event) => {
    //   setSelectedFile3(URL.createObjectURL(event.target.files[0]))
    //   files[2] = event.target.files[0]
    //   setFiles([...files])
    // }
  
    // const handleFileSelect4 = (event) => {
    //   setSelectedFile4(URL.createObjectURL(event.target.files[0]))
    //   files[3] = event.target.files[0]
    //   setFiles([...files])
    // }
  
    // const handleSetIsCheckedChange = (checked, index) => {
    //   isChecked[index] = checked
    //   // let int;
    //   setIsChecked((prev) => ({ ...prev, [index]: checked }))
  
    //   // if (checked) {
    //   //   saveServicesSingle(index)
    //   // }
    // }
  
    // console.log("checkingHaDev timer check", timer)
  
    // const handleTextChange = (event, index) => {
    //   setTextValue((prev) => ({ ...prev, [index]: event.target.value || "" }))
    // }
    // const handleFillChange = (event, index) => {
    //   setfillValue((prev) => ({ ...prev, [index]: event.target.value || "" }))
    // }
    // const handleValvalueChange = (event, index) => {
    //   setvalValue((prev) => ({ ...prev, [index]: event.target.value || "" }))
    // }
  
    // const startTimerController = (index) => {
    //   const alreadyStarted = timerRef.current[index]
    //   console.log("checkingHaDev startTimerController", index, alreadyStarted)
  
    //   if (!alreadyStarted) {
    //     timerRef.current[index] = setInterval(() => {
    //       timers.current[index] = (timers.current[index] || 0) + 1
  
    //       setTimer((prev) => ({ ...prev, [index]: (prev[index] || 0) + 1 }))
    //     }, 1000)
    //   }
    // }
    // const endTimerController = (index) => {
    //   const alreadyStarted = timerRef.current[index]
  
    //   if (alreadyStarted) {
    //     const timerValue = timers.current[index]
  
    //     if (!timerValue) {
    //       timers.current[index] = 0
    //     }
  
    //     clearInterval(timerRef.current[index])
    //   }
    // }
  
    // const handleYesCheckboxToggle = (checked = false, index) => {
    //   setIsYesChecked((prev) => ({ ...prev, [index]: checked }))
    //   if (checked) startTimerController(index)
    //   else endTimerController(index)
    // }
  
    // const saveServicesSingle = async (i) => {
    //   const data = {
    //     is_active: isYesChecked[i],
    //     comment: textValue[i],
    //     executed: isChecked[i],
    //     fill: "Some fill value",
    //     value: 10.0,
    //     product: product_id,
    //     name: "Service One",
    //     time_spent: timerRef.current[i],
    //   }
  
    //   try {
    //     if (!data) {
    //       alert("Service form is empty!")
    //       return
    //     }
    //     const res = await axios.post(
    //       `${process.env.REACT_APP_API_URL}/product-service/create/`,
    //       data
    //     )
    //     var formdata = new FormData()
    //     formdata.append(
    //       "description",
    //       `${services[i].name} history has been created with in ${timers.current[i]} s`
    //     )
    //     formdata.append("product", db_data?.id)
  
    //     var requestOptions = {
    //       method: "POST",
    //       body: formdata,
    //       redirect: "follow",
    //     }
    //     fetch(
    //       `${process.env.REACT_APP_API_URL}/history/create/`,
    //       requestOptions
    //     )
    //       .then((response) => response.text())
    //       .then((result) => console.log(result))
    //       .catch((error) => console.log("error", error))
  
    //     alert("New Service has been created")
    //     if (res) {
    //       console.log("response of services ", res)
    //     }
    //   } catch (error) {
    //     console.log("Error uploading file: ", error)
    //   }
    // }
    const handleFileSelect5 = (event) => {
      setSelectedFile5(URL.createObjectURL(event.target.files[0]))
  
      setDataPost({
        ...dataPost,
        picture:event.target.files[0]
      })
    }
    console.log("checkingHaDev save services isChecked", isChecked)
    // const saveServices = async (e) => {
    //   e.preventDefault()
    //   const data = []
    //   console.log("checkingHaDev save services data s_______", isChecked)
    //   var time_sum = 0
    //   for (const index in isChecked) {
    //     if (Object.hasOwnProperty.call(isChecked, index)) {
    //       console.log(
    //         "checkingHaDev save services for",
    //         index,
    //         isChecked[index],
    //         isYesChecked
    //       )
    //       const value = isChecked[index]
  
    //       if (value) {
    //         data.push({
    //           is_active: isYesChecked[index],
    //           comment: textValue[index] || "",
    //           executed: isChecked[index] || "",
    //           fill: fillvalue[index] || "",
    //           value: valvalue[index] ? parseInt(valvalue[index]) : "",
    //           product: product_id,
    //           name: services.data[index].name,
    //           time_spent: timerRef.current[index],
    //           user: profile_id,
    //         })
    //         time_sum += timerRef.current[index]
    //       }
    //     }
    //   }
  
    //   console.log("checkingHaDev save services time data ", time_sum, data)
    //   if (data.length==0) {
    //     alert("Services is empty!")
    //     return
    //   }else{  try {
        
    //     const res = await axios.post(
    //       // `${process.env.REACT_APP_API_URL}/product-service/create/`,
    //       `${process.env.REACT_APP_API_URL}/product-service/create/`,
    //       data
    //     )
  
    //     if (res) {
    //       console.log("response of services ", res)
    //     }
    //     var formdata = new FormData()
    //     formdata.append(
    //       "description",
    //       `${data.length} Services has been created successfully with in ${time_sum} s`
          
          
    //         // "timestamp": "2023-09-21T12:00:00Z",
    //         // "description": "Description of the historical evffffffffffffffffffffffffffffffffent",
          
        
    //     )
    //     formdata.append("historical_note", ``)
    //       formdata.append("vehicle", db_data?.vehicle_id)
    //       formdata.append("owner_email", db_data?.orderer_email)
    //       formdata.append("plate_number", db_data?.plate_number)
    //       formdata.append("vin_code", db_data?.vin_code)
  
    //     var requestOptions = {
    //       method: "POST",
    //       body: formdata,
    //       redirect: "follow",
    //     }
    //     fetch(
    //       `${process.env.REACT_APP_API_URL}/history/create/`,
    //       requestOptions
    //     )
    //       .then((response) => response.text())
    //       .then((result) => console.log(result))
    //       .catch((error) => console.log("error", error))
    //     alert(" history has been created")
    //     navigate("/vehicleData")
    //   } catch (error) {
    //     console.log("Error uploading file: ", error)
    //   }}
    
    // }
    // const postImgs = async (e) => {
    //   e.preventDefault()
    //   const data = new FormData()
    //   data.append("product", product_id)
    //   for (let i = 0; i < files.length; i++) {
    //     if (files[i] != null) {
    //       data.append(`image${i + 1}`, files[i])
    //     }
    //   }
    //   if (files.length==0) {
    //     alert("Value is empty!")
    //     return
    //   }else{
    //     try {
    //       const res = await axios.post(
    //         `${process.env.REACT_APP_API_URL}/service-image/`,
    //         data
    //       )
    //       if (res) {
    //         setSelectedFile1("")
    //         setSelectedFile2("")
    //         setSelectedFile3("")
    //         setSelectedFile4("")
    //       }
    //       // alert("service Images  has been saved")
          
    //     } catch (error) {
    //       console.log("Error uploading file: ", error)
    //     }
    //   }
     
    // }
  
    // const cardData = async (e) => {
    //   e.preventDefault()
    //   if (!isChecked || !textValue || !isYesChecked) return
    //   const post = {
    //     is_active: isYesChecked,
    //     comment: textValue,
    //     executed: isChecked,
    //     product: product_id,
    //   }
  
    //   const res = await axios.post(
    //     `${process.env.REACT_APP_API_URL}/create/product-service/`,
    //     post
    //   )
    //   if (res.data) {
    //     setTextValue("")
    //     setIsYesChecked({})
    //     setIsChecked({})
    //   }
    // }
  
    // const handleSubmit = async (e) => {
    //   if (comment.length>0 && files.length>0 ) {
  
    //   try {
    //     await saveServices(e);
    //   } catch (error) {
    //     console.log("Error in saveServices:", error);
    //     alert("Error: saveServices failed!");
    //     return;
    //   }
    
    //   try {
    //     await postImgs(e);
    //   } catch (error) {
    //     console.log("Error in postImgs:", error);
    //     alert("Error: postImgs failed!");
    //     return;
    //   }
    
     
    //     await cardData(e);
      
    //   e.preventDefault()
    //   const post = { product: product_id, note: comment }
    //   if (comment.length==0) {
    //     alert("Mechenical comments is empty!")
    //     return
    //   }else{
    //     try {
    //       const res = await axios.post(
    //         `${process.env.REACT_APP_API_URL}/mechanical-notes/create/`,
    //         post
    //       )
    
    //       var formdata = new FormData()
    //       formdata.append("description", "Images and comments has been created")
    //       formdata.append("product", db_data?.id)
    
    //       var requestOptions = {
    //         method: "POST",
    //         body: formdata,
    //         redirect: "follow",
    //       }
    //       fetch(
    //         `${process.env.REACT_APP_API_URL}/history/create/`,
    //         requestOptions
    //       )
    //         .then((response) => response.text())
    //         .then((result) => console.log(result))
    //         .catch((error) => console.log("error", error))
    
    //       alert("Images, comments and history has been created")
    //       navigate("/vehicleData")
    
    //       if (res) {
    //         setComment("")
    //       }
    //     } catch (e) {
    //       alert(e)
    //     }
    //   }
    // }
    // else{
    //   toast.error(
    //     `Please fill all Fields`
    //     , {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //     });
    // }
      
    // }
    const savebuletin = ()=>{
      console.log("datapost",dataPost)
  
      if(dataPost?.comment==""){
        alert("Please provide mechenical note")
      }
      
      if(!dataPost?.picture){
        alert("Please select picture")
      }
      if(!dataPost?.video){
        alert("Please select video")
      }
      else{
        navigate("/vehicleData");
        // postImgs();
        // console.log("lllllllllllkkkkkkkkkkkkkkkkkkk",FormData())
        const data = new FormData()
       
        // data.append("maintanence_id", dataPost?.maintanence_id)
        data.append("bulletins_id", parseInt(firstBulletinid))
        data.append("bulletins_mechanical_note", comment)
       
        
        data.append("picture", dataPost?.picture)
        // data.append("video", dataPost?.video)
        
        
        
        console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz",data);
        // console.log(data.getAll("replace_parts"));
        const res =  axios.post(
          `${process.env.REACT_APP_API_URL}/api/add-bulletins-completed/`,
          // `${process.env.REACT_APP_API_URL}/api/warranty/create/`,
          data
        ).then((res)=>{
          
          var formdata = new FormData()
          formdata.append("description", `bulletin saved against SKU ${db_data?.sku} `)
          formdata.append(
            "estonian_description",
            `Teadaanne salvestatud SKU vastu. ${db_data?.sku} `
          )
          formdata.append(
            "latvian_description",
            `Biļetens saglabāts pret SKU.${db_data?.sku} `
          )
          formdata.append(
            "finnish_description",
            `Tiedote tallennettu SKU:ta vasten. ${db_data?.sku} `
          )
          formdata.append("historical_note", ``)
          formdata.append("vehicle", db_data?.vehicle_id)
          formdata.append("owner_email", db_data?.orderer_email)
          formdata.append("plate_number", db_data?.plate_number)
          formdata.append("vin_code", db_data?.vin_code)
    
          var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
          }
          fetch(
            `${process.env.REACT_APP_API_URL}/history/create/`,
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              console.log("expected=============>",result);
              // Redirect to the desired location
             
            })
            .catch((error) => console.log("error", error))
    
    
        })
    }
      
    }
  
    const allAPICall = async () => {
      const res = await axios.get(
        // `${process.env.REACT_APP_API_URL}//getallservices/`
        `${process.env.REACT_APP_API_URL}/getallservices/`
      )
      console.log("----------", res)
      setServices(res)
    }
    useEffect(() => {
      allAPICall()
    }, [])
    console.log("------->>>>>>>>>>>>>>.--", services)
  
    const handleConfirmService = async (name, instruction) => {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/services/create/`,
        {
          name: name,
          instructions: instruction,
          instruction_active: true,
        }
      )
      allAPICall()
    }
  
    console.log("services__services__services__services__", services)
  
    // const surveyFormContextValues = {
    //   data: services?.data || [],
    //   fillvalue,
    //   handleFillChange,
    //   valvalue,
    //   handleValvalueChange,
    //   handleYesCheckboxToggle,
    //   startTimerController,
    //   endTimerController,
    //   textValue,
    //   handleTextChange,
    //   handleSetIsCheckedChange,
    // }
  
    // const getProductImages = ()=>{
    //   const res =  axios.get(
    //     // `${process.env.REACT_APP_API_URL}/product-service/create/`,
    //     // `${process.env.REACT_APP_API_URL}/api/service_image/${db_data?.id}/`,
    //     `${process.env.REACT_APP_API_URL}/api/service_image/${db_data?.id}/`,
    //   ).then((res)=>{
    //     if (res?.data && res.data.length > 0) {
    //       const data = res.data[0];
          
    //       if (data.image1) {
    //         setSelectedFile1(`${process.env.REACT_APP_API_URL}/${data.image1}`);
    //       }
          
    //       if (data.image2) {
    //         setSelectedFile2(`${process.env.REACT_APP_API_URL}/${data.image2}`);
    //       }
          
    //       if (data.image3) {
    //         setSelectedFile3(`${process.env.REACT_APP_API_URL}/${data.image3}`);
    //       }
          
    //       if (data.image4) {
    //         setSelectedFile4(`${process.env.REACT_APP_API_URL}/${data.image4}`);
    //       }
    //     }
  
    //  })
    // }
    function downloadPdfFile(pdfUrl) {
        // Create an anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = 'your_file_name.pdf'; // Set the desired file name here
      
        // Trigger a click event on the anchor element
        link.click();
      }
      
  return (
    <>
    <div className="flex">
      <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

      <Header setOpenSidebar={setOpenSidebar} />

      <main className="md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
          {/* ADD NEW SERVICE */}
          <div className="px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16">
            <div className="flex flex-col md:flex-row mb-[22px] md:mb-[30px] xl:mb-14">
              {/* LEFT */}
              <div className="w-full mb-10 md:mb-0 md:w-1/2 md:pt-3 xl:pt-12">
                {/* IMAGE */}
                <div>
                  <img className="mx-auto" src={image_url} alt="bike-img" />
                </div>
              </div>

              {/* RIGHT */}
              <div className="w-full md:w-1/2 flex flex-col self-center">
                <div className="md:pl-5 xl:pl-[30px]">
                  {/* BIKE NAME */}
                  <div className="mb-3 md:mb-1 xl:mb-[86px]">
                  <h3 className="bg-gradient-text text-f_19_l_24 md:text-f_22_l_28 xl:text-f_27_l_34 text-left font-supremeBold">
                    {db_data?.model_name}, {db_data?.year},{" "}
                    {db_data?.color}
                  </h3>
                </div>

                {/* SERVICE-KM */}
                <div className="mb-3 md:mb-[30px] xl:mb-[83px]">
                  <h1 className=" text-blue text-f_16_l_20 md:text-f_22_l_28 xl:text-f_35_l_44 font-supremeBold">
                  {bulletinnewdata[0]?.bulletins_name} 
                  </h1>
                </div>

                {/* SERVICE DESCRIPTION */}
                <div>
                        <h4 className="text-f_16_l_20 md:text-f_18_l_23 xl:text-f_22_l_28 font-supremeBold text-white-100 mb-3 md:mb-1.5 xl:mb-4">
                
      </h4>
                          
                        </div>
                </div>
              </div>
            </div>

           

            {/* VEHICLE PHOTOS */}
            <div className="mb-[67px] md:mb-[41px] xl:mb-[90px]">
              {/* TITLE */}
              <div className="mb-6 md:mb-14 xl:mb-[43px]">
                <h3 className="text-f_20_l_25 md:text-f_22_l_28 text-white-100 font-supremeBold mb-3 md:mb-2.5">
                {t("Bulletins_description") }
                </h3>

                <p className="text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100 text-left">
  {bulletinnewdata[0]?.bulletins_description}
</p>

              </div>

           
            </div>
            <div className="mb-[67px] md:mb-[41px] xl:mb-[90px] flex">
            <div className="w-3/5  flex items-center">
  {/* 60% width */}
  <h3 className="text-f_20_l_25 md:text-f_22_l_28 text-white-100 font-supremeBold mb-3 md:mb-2.5">
  {t("Factory_instructions") }
  </h3>
</div>
  <div className="w-3/5 pl-4 flex flex-col">
  {/* 60% width */}
  <div className="w-3/5 pl-4 flex">
  {/* 60% width */}
  <button
  className="w-full md:w-[55%] h-12 xl:h-[73px] rounded-[10px] border border-white xl:text-f_22_l_28 font-supremeMedium uppercase"
  onClick={() =>
    downloadPdfFile(pdf_url)
  }
  style={{ color: 'red', borderColor: 'red' }}
>
{t("OPEN_FILE") }
</button>




</div>


</div>

</div>




          
          
                 {/* VEHICLE PHOTOS */}
                 <div className="mb-[67px] md:mb-[41px] xl:mb-[90px]">
              {/* TITLE */}
              <div className="mb-6 md:mb-14 xl:mb-[43px]">
                <h3 className="text-f_20_l_25 md:text-f_22_l_28 text-white-100 font-supremeBold mb-3 md:mb-2.5">
                {t("Upload_photos_and_videos") }
                </h3>
                {/* <p className=" text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100">
                  Photo of the speedometer (numbers must be legible), VIN code
                  (numbers must be legible), 45° front view ja 45° rear view
                </p> */}
              </div>

              <div className="grid grid-cols-2 xl:grid-cols-4 gap-5 md:gap-y-[27px] md:gap-x-[30px]">
                {/* IMAGE 1 */}
                <div>
                        {/* IMAGE AREA */}
                        <div
                          className="w-full h-[156px] md:h-[210px] lg:h-[305px] xl:h-[280px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]"
                          style={{
                            backgroundImage: selectedFile5
                              ? `url(${selectedFile5})`
                              : "none",
                          }}
                          onClick={(e) => handleImageClick2(fileInputRef5)}>
                          {!selectedFile5 && (
                            <div className="flex flex-col justify-center items-center cursor-pointer">
                              <img src={placeholder_img} alt="add-img" />
                              <div className=" text-f_18_l_28 mt-3.5">Add Photo</div>
                            </div>
                          )}
                        </div>

                        {/* INPUT */}
                        <input
                          type="file"
                          ref={fileInputRef5}
                          style={{ visibility: false, display: "none" }}
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={handleFileSelect5}
                        />
                      </div>
                {/* IMAGE 2 */}
                <div>
                        {/* IMAGE AREA */}
                        <div
                          className="w-full h-[156px] md:h-[210px] lg:h-[305px] xl:h-[280px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]"
                          
                          style={{
                            backgroundImage: selectedFile6
                              ? `url(${selectedFile6})`
                              : "none",
                              backgroundSize: "cover", // Stretch the background image to cover the container
                              backgroundPosition: "center",
                          }}
                          onClick={(e) => handleImageClick(fileInputRef6)}>
                          {!selectedFile6? (
                            <div className="flex flex-col justify-center items-center cursor-pointer">
                              <img src={placeholder_img} alt="add-img" />
                              <div className=" text-f_18_l_28 mt-3.5">Add Video</div>
                            </div>
                          ):(
                            <>
                            
                            <video src={selectedFile6} 
                            style={
                              {
                                height: "100%",
                                objectFit: "fill",
                              }
                            }
                          ></video>
                            </>
                          )}
                        </div>

                        {/* INPUT */}
                        <input
                          type="file"
                          ref={fileInputRef6}
                          style={{ visibility: false, display: "none" }}
                          accept="video/mp4, video/quicktime"
                          onChange={handleFileSelect6}
                        />
                      </div>

              

               
              </div>
            </div>
              {/* SPARE PARTS FOR SERVICE */}
              <div className="mb-7">
              <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5">
              {t("bulettin_Mechanic_comment") }
              </h3>

              <textarea 
              value={comment}
              onChange={(e)=>setComment(e.target.value)}
              className="h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full" />
            </div>
            {/* SAVE SERVICE DATA BUTTON */}
            <button

              className="p-4 bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium"
              onClick={(event)=>{
                savebuletin();
                event.target.style.display = 'none';
              }}
              >
            {t("Bulletin_executed") }
            </button>
          </div>
        </main>
    </div>
  </>
  )
}

export default Bulletin