import React from "react";
import { useSelector } from "react-redux";

const LoadingOverlay = () => {
  const loading = useSelector((state) => state.loader.loading);
  if (!loading) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-neutral-950 bg-opacity-50 z-50">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 800 500"
        style={{
          enableBackground: "new 0 0 800 500",
          width: "800px",
          height: "800px",
        }}
        xmlSpace="preserve"
      >
        <style>{`
          path {
            fill: #4949480D;
            stroke: #FFF;
            stroke-dasharray: 600;
            stroke-width: 2;
            animation: dash-animation 2s infinite ease-in-out;
          }
          @keyframes dash-animation {
            0% {
              stroke-dashoffset: 0;
            }
            50% {
              stroke-dashoffset: 800;
            }
            100% {
              stroke-dashoffset: 1600;
            }
          }
        `}</style>
        <g id="Layer_2">
          <path
            className="st2"
            d="M400,151c-54.6,0-99,44.4-99,99c0,54.6,44.4,99,99,99c54.6,0,99-44.4,99-99C499,195.4,454.6,151,400,151
             M400,326.9c-42.5,0-76.9-34.4-76.9-76.9s34.4-76.9,76.9-76.9c42.5,0,76.9,34.4,76.9,76.9S442.5,326.9,400,326.9 M435.2,222.4
            c-14,0-26.2-4.1-26.2-4.1s-12.8-4.4-26.1-4.4c-8.3,0-17.2,1.7-22.8,3c-3.4,0.8-6.1,3.4-7,6.7l-4.8,17.6h0l-10,36.7l-1.1,3.9
            c0,0,15.1-4.4,28.4-4.4c13.3,0,26.1,4.4,26.1,4.4s12.2,4.1,26.2,4.1c8.6,0,17.8-1.3,23.1-2.8c3.4-1,6.2-3.4,7.1-6.7l4.8-17.5
            c0,0-14.7,4.2-28.7,4.2c-14,0-26.2-4.1-26.2-4.1s-12.8-4.4-26.1-4.4c-0.8,0-1.7,0-2.6,0.1l4.8-17.7c0.9,0,1.7-0.1,2.6-0.1
            c13.3,0,26.1,4.4,26.1,4.4s12.2,4.1,26.2,4.1c8.6,0,17.5-1.2,23.1-2.8c3.4-1,6.2-3.4,7.1-6.7l4.8-17.5
            C463.9,218.1,449.2,222.4,435.2,222.4"
          />
        </g>
      </svg>
    </div>
  );
};

export default LoadingOverlay;
