import video_poster from "../../images/video-poster.png";
const breakpoints = [3840, 1920, 1080, 640, 384, 256, 128];

export const slidesFormatter = (imageURLs) =>
  imageURLs.map((item) => {
    if (!item) {
      return null;
    }

    const isVideo = item.endsWith(".mp4");

    if (isVideo) {
      return {
        type: "video",
        width: 1280,
        height: 720,
        poster: video_poster,
        sources: [
          {
            src: item,
            type: "video/mp4",
          },
        ],
      };
    }

    return {
      src: item,
      width: 3840,
      height: 5760,
      srcSet: breakpoints.map((breakpoint) => ({
        src: item,
        width: breakpoint,
        height: Math.round((5760 / 3840) * breakpoint),
      })),
    };
  });
