import axios from "axios";

const userInstance = axios.create({
  // baseURL: "https://cfmotoworld.pythonanywhere.com/",
  baseURL: `${process.env.REACT_APP_API_URL}/`,
  // baseURL: "http://127.0.0.1:8000/",
});
// const newuserInstance = axios.create({
//   // baseURL: "https://cfmotoworld.pythonanywhere.com/",
//   baseURL: "http://127.0.0.1:8000/",
// });
export const signUp = (user) =>
  userInstance.request({ method: "POST", data: user, url: "/api/user/register/" });

export const signIn = (user) =>
  userInstance.request({ method: "POST", data: user, url: "/api/user/login/" });

export const profileData = (token) =>
  userInstance.request({ method: "GET", url: "/api/user/profile/",headers:{"Authorization":`Bearer ${token}`} });

export const vinnIn = (number) =>
 userInstance.request({ method: "GET", url: `/vehicles/product/vin/${number}?user=${localStorage.getItem("profile_email")}` });

//  export const vinnIn = (number) =>
//  newuserInstance.request({ method: "GET", url: `/vehicles/product/vin/${number}/` });