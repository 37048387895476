import { combineReducers } from "redux";
import { userReducer } from "./user";
import { notificationReducer } from "./notification";
import { vinnReducer } from "./vinn";
import { reducer as formReducer } from "redux-form";
import { loaderReducer } from "./loader";

export const reducer = combineReducers({
  user: userReducer,
  form: formReducer,
  notification: notificationReducer,
  vinn: vinnReducer,
  loader: loaderReducer,
});
