import React from "react";

const Show = ({ IF: condition, children }) => {
  if (condition) {
    return children;
  }

  return <></>;
};

export default Show;
