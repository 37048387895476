import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
// import {  toast } from 'react-toastify';
// import CalendarGfg from "../components/Common/celander"
import info_icon from "../images/info_icon.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import SelectService from "../components/Settings/SelectService";
import Select from "react-tailwindcss-select";
import SelectSeries from "../components/Settings/SelectSeries";
import Sidebar from "../components/Common/Sidebar";
import Header from "../components/Common/Header";
import bike_img from "../images/bike_img.png";
import placeholder_img from "../images/camera_img.svg";
import SurveyForm from "../components/Service/Surveyform";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loaderActions } from "../state/actions";

function Addwork() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState(new Date());
  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const data = localStorage.getItem("vinnData");
  const [showModal, setShowModal] = useState(false);
  const [selectedMileage, setSelectedMileage] = useState(null);
  const [workStatusData, setWorkStatusData] = useState(null);
  const [prevCurrentMileage, setprevCurrentMileage] = useState("");
  const [previousDate, setpreviousDate] = useState("");
  const profileId = +localStorage.getItem("profile_id");
  const parsed_data = JSON.parse(data);
  const db_data = parsed_data?.data;
  const vin = localStorage.getItem("vinnData");
  const profile = localStorage.getItem("profile");
  const company_name = localStorage.getItem("company_name");

  const location = useLocation(); // To get query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const isReadOnly =
    queryParams.get("readOnly") === "1" ||
    workStatusData?.is_maintenance ||
    workStatusData?.is_repair ||
    workStatusData?.is_warranty;
  const workIdFromURL = queryParams.get("workId");

  const [mileageOptions, setMileageOptions] = useState([]);

  const [selectedYear, setSelectedYear] = useState(null);
  const yearOptions = [
    { value: 2022, label: "2022" },
    { value: 2023, label: "2023" },
    { value: 2024, label: "2024" },
    { value: 2025, label: "2025" },
  ];
  const storedItem = localStorage.getItem("bulletinData");
  console.log("86756tjjgtut768============", storedItem);
  const product_id = db_data.vehicle_id;
  const dateStr = db_data?.date_of_manufacture;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  console.log("db_data+++++>> ", db_data);
  const image_url = `${db_data?.image}`;
  // const [openSidebar, setOpenSidebar] = useState(false)

  const [dataPost, setDataPost] = useState({
    vin_code: db_data.vin_code,
    user: profileId,
    vehicle: db_data.id,
    work_order_description: "",
    is_maintenance: false,
    is_year: false,
    maintenance_year_or_mileage: null,
    is_repair: false,
    repair_description: "",
    is_warranty: false,
    warranty_description: "",
    estimated_mileage: 0,
    work_orderer: "",
    work_orderer_email: "",
    work_orderer_phone_no: "",
  });
  const saveWork = async () => {
    if (
      !dataPost.work_order_description ||
      !dataPost.estimated_mileage ||
      dataPost.estimated_mileage <= 0
    ) {
      toast.error(`Please fill Owner Description & Mileage.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if (!dataPost.work_orderer || !dataPost.work_orderer_email) {
      toast.error(`Please fill Work Orderer name, & email.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    try {
      dispatch(loaderActions.showLoader());
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/addwork/add-work/`,
        { ...dataPost }
      );

      if (response.status === 201) {
        // Call another function upon successful API call
        toast.success(`Work created successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // History API call
        const formdata = new FormData();
        formdata.append(
          "description",
          `Work created by ${profile} from ${company_name}`
        );
        formdata.append(
          "estonian_description",
          `Töökäsk on loodud ${profile} poolt ettevõttest ${company_name}`
        );
        formdata.append(
          "latvian_description",
          `Darbu izveidoja ${profile} no uzņēmuma ${company_name}`
        );
        formdata.append(
          "finnish_description",
          `Teoksen on luonut ${profile} yrityksestä ${company_name}`
        );
        formdata.append("historical_note", ``);
        formdata.append("vehicle", db_data?.id);
        formdata.append("owner_email", db_data?.owner_email);
        formdata.append("plate_number", db_data?.plate_number);
        formdata.append("vin_code", db_data?.vin_code);
        formdata.append("work_id", response?.data?.work_id);

        const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_API_URL}/history/create/`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));

        // alert("");
        navigate("/vehicleData");
      } else {
        // Handle other status codes if needed
      }
    } catch (error) {
      // Handle API call errors
      toast.error(`Error while saving work`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.error("Error while saving work:", error);
    } finally {
      dispatch(loaderActions.hideLoader());
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/getallmileages/`)
      .then((response) => {
        const options = response.data.map((item) => ({
          value: item.id,
          label: item.Mileage,
        }));
        setMileageOptions(options);
      })
      .catch((error) => {
        console.error("Error fetching mileage options:", error);
      });
  }, []);

  const onChangeValue = (event, key) => {
    const value = event.target.value;
    setDataPost((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleMileageOrYearChange = (selectedOption) => {
    if (dataPost?.is_year) {
      setSelectedYear(selectedOption);
    } else {
      setSelectedMileage(selectedOption);
    }
    setDataPost((prevState) => ({
      ...prevState,
      maintenance_year_or_mileage: selectedOption.value,
    }));
  };

  useEffect(() => {
    if (workIdFromURL && mileageOptions) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/addwork/add-work/${workIdFromURL}`
        )
        .then((response) => {
          if (
            yearOptions
              ?.map((item) => item.value)
              .includes(response.data?.maintenance_year_or_mileage)
          ) {
            setDataPost({ ...response.data, is_year: true });
            setSelectedYear(
              yearOptions?.find(
                (item) =>
                  item.value === response.data?.maintenance_year_or_mileage
              )
            );
          } else {
            setDataPost(response.data);
            setSelectedMileage(
              mileageOptions?.find(
                (item) =>
                  item.value === response.data?.maintenance_year_or_mileage
              )
            );
          }
        })
        .catch((error) => {
          toast.error("Error fetching works.");
          console.error("Error fetching data:", error);
        });
    }
  }, [mileageOptions]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/addwork/add-work/check-status/${db_data?.vin_code}/`
      )
      .then((response) => {
        setWorkStatusData(response.data);
        if (
          response.data.is_maintenance ||
          response.data.is_repair ||
          response.data.is_warranty
        ) {
          toast.error(
            "New work cannot be added until any pending repair, maintenance, or warranty is resolved."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/vehicle-maintenance-history/${db_data?.vin_code}/`
      )
      .then((response) => {
        setprevCurrentMileage(
          response.data[response.data.length - 1].current_mileage
        );
        setpreviousDate(response.data[response.data.length - 1].date);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="flex">
      <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

      <Header setOpenSidebar={setOpenSidebar} />

      <main className="md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
        {/* ADD WORK */}
        <div className="px-4 md:px-6 xl:px-10 pt-6 md:pt-10 xl:pt-12 pb-8 xl:pb-14">
          <div className="flex flex-col md:flex-row mb-6 md:mb-8 xl:mb-10">
            {/* LEFT */}
            <div className="w-full md:w-1/2 flex flex-col self-center mb-6 md:mb-0">
              <h3 className="text-blue text-f_18_l_22 md:text-f_24_l_30 xl:text-f_32_l_40 font-supremeBold">
                Add Work
              </h3>
            </div>

            {/* RIGHT */}
            <div className="w-full md:w-1/2 flex flex-col self-center">
              <div className="md:pl-5 xl:pl-8">
                <h3 className="bg-gradient-text text-f_18_l_22 md:text-f_22_l_28 xl:text-f_27_l_34 font-supremeBold">
                  {db_data?.model_name}, {db_data?.year}, {db_data?.color}
                </h3>
              </div>
            </div>
          </div>

          {/* VEHICLE OWNER DESCRIPTION */}
          <div className="mb-8">
            <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-4">
              Vehicle Owner Description
              <span className="text-error ml-2">*</span>
            </h3>
            <textarea
              readOnly={isReadOnly ? true : undefined}
              value={dataPost?.work_order_description}
              onChange={(event) =>
                onChangeValue(event, "work_order_description")
              }
              className="h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full px-4 py-4"
              placeholder="Write vehicle owner description"
            />
          </div>

          {/* MILEAGE SECTION */}
          <div className="mb-8 flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0">
            <div className="flex flex-row xsm:flex-column items-start">
              <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold">
                The current mileage is approx
                <span className="text-error ml-2">*</span>
              </h3>
              <div className="mx-2 w-[200px] xsm:w-[160px] bg-black-500 select">
                <input
                  readOnly={isReadOnly ? true : undefined}
                  id="estimated_mileage"
                  value={dataPost.estimated_mileage}
                  onChange={(event) =>
                    setDataPost((prevState) => ({
                      ...prevState,
                      estimated_mileage: event.target.value,
                    }))
                  }
                  placeholder="978"
                  type="number"
                  min={0}
                  class="rounded-lg px-3 py-2 bg-[#181717] border border-gray-400 w-[200px] xsm:w-[160px] text-right"
                />
              </div>
              <div className="flex items-center space-x-2">
                <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold">
                  km
                </h3>
                <img
                  src={info_icon}
                  className="h-5 w-5 text-gray-600"
                  alt="info-icon"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 space-y-4 md:space-y-0 md:space-x-4 md:w-[70%]">
            {/* MAINTENANCE */}
            <div className="flex items-center space-x-12">
              <label
                htmlFor="is_maintenance"
                className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold"
              >
                Maintenance
              </label>
              <input
                disabled={isReadOnly}
                id="is_maintenance"
                checked={dataPost.is_maintenance}
                type="checkbox"
                className="w-[19px] h-[19px] text-transparent bg-transparent border-[0.5px] border-black-700 rounded-sm checked:border-black-700 focus:ring-transparent focus:ring-offset-0 focus:ring-2"
                onChange={(event) =>
                  setDataPost((prevState) => ({
                    ...prevState,
                    is_maintenance: event.target.checked,
                  }))
                }
              />
            </div>

            {/* YEAR */}
            {/* <div className="flex items-center space-x-12">
              <label
                htmlFor="is_year"
                className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold"
              >
                Year
              </label>
              <input
                disabled={isReadOnly}
                id="is_year"
                checked={dataPost.is_year}
                type="checkbox"
                className="w-[19px] h-[19px] text-transparent bg-transparent border-[0.5px] border-black-700 rounded-sm checked:border-black-700 focus:ring-transparent focus:ring-offset-0 focus:ring-2"
                onChange={(event) =>
                  setDataPost((prevState) => ({
                    ...prevState,
                    is_year: event.target.checked,
                  }))
                }
              />
            </div> */}

            {/* OR */}
            <h3 className="text-f_18_l_22 md:text-f_22_l_28 xl:text-f_27_l_34 font-supremeBold">
              {/* or */}
            </h3>

            {/* MILEAGE DROPDOWN */}
            {/* <div className="min-w-[235px] h-[45px] bg-black-500 select">
              <Select
                isDisabled={isReadOnly}
                value={dataPost.is_year ? selectedYear : selectedMileage}
                onChange={handleMileageOrYearChange}
                options={dataPost.is_year ? yearOptions : mileageOptions}
                placeholder={dataPost.is_year ? "Select year" : "Select km"}
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex w-full min-h-[45px] text-f_18_l_23 text-white border-[0.5px] border-white-100 rounded-[10px] transition-all duration-300 focus:ring-0 focus:outline-none ${
                      isDisabled
                        ? ""
                        : "bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                    }`,
                  menu: "absolute z-10 w-full bg-black-500 shadow-lg border rounded-[10px] py-1 mt-1.5 text-f_18_l_23",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? `text-white-100 bg-blue-500`
                        : `text-white-100 hover:bg-blue-100 hover:text-blue-500`
                    }`,
                  tagItem: () =>
                    "bg-black-600 text-white-100 text-[12px] border rounded-sm flex space-x-1 pl-1",
                }}
              />
            </div> */}
            <div className="mx-2 flex items-center w-full select">
              <label className="mr-2 font-supremeBold">
                {previousDate
                  ? `The Last service was performed on ${previousDate}, and the mileage was ${
                      prevCurrentMileage || 0
                    } km`
                  : "This vehicle has no previous service history in the CFMOTO World system."}
              </label>
            </div>
          </div>

          {/* REPAIRING */}
          <div className="flex items-center space-x-20">
            <label
              htmlFor="is_repair"
              className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mr-2"
            >
              Repairing
            </label>
            <input
              disabled={isReadOnly}
              id="is_repair"
              checked={dataPost.is_repair}
              type="checkbox"
              className="w-[19px] h-[19px] text-transparent bg-transparent border-[0.5px] border-black-700 rounded-sm checked:border-black-700 focus:ring-transparent focus:ring-offset-0 focus:ring-2"
              onChange={(event) =>
                setDataPost((prevState) => ({
                  ...prevState,
                  is_repair: event.target.checked,
                }))
              }
            />
          </div>

          {/* REPAIRING DESCRIPTION */}
          <div className="my-8">
            <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-4">
              Repairing Description
            </h3>
            <textarea
              readOnly={
                isReadOnly || (!isReadOnly && !dataPost?.is_repair)
                  ? true
                  : undefined
              }
              value={dataPost?.repair_description}
              onChange={(event) => onChangeValue(event, "repair_description")}
              className="h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full px-4 py-2"
              placeholder="Description to mechanic"
            />
          </div>

          {/* WARRANTY */}
          <div className="flex items-center space-x-20 mb-8">
            <label
              htmlFor="is_warranty"
              className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mr-2"
            >
              Warranty
            </label>
            <input
              disabled={isReadOnly}
              id="is_warranty"
              checked={dataPost.is_warranty}
              type="checkbox"
              className="w-[19px] h-[19px] text-transparent bg-transparent border-[0.5px] border-black-700 rounded-sm checked:border-black-700 focus:ring-transparent focus:ring-offset-0 focus:ring-2"
              onChange={(event) =>
                setDataPost((prevState) => ({
                  ...prevState,
                  is_warranty: event.target.checked,
                }))
              }
            />
          </div>

          {/* WARRANTY DESCRIPTION */}
          <div className="mb-8">
            <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-4">
              Warranty description
            </h3>
            <textarea
              readOnly={
                isReadOnly || (!isReadOnly && !dataPost?.is_warranty)
                  ? true
                  : undefined
              }
              value={dataPost?.warranty_description}
              onChange={(event) => onChangeValue(event, "warranty_description")}
              className="h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full px-4 py-2"
              placeholder="Description to mechanic"
            />
          </div>

          {/* CALENDAR SECTION */}
          {/* <div className="mb-8">
            <Calendar onChange={onChange} value={value} />
          </div> */}

          {/* Work Orderer Name */}
          <div className="mb-8">
            <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-4">
              Work Orderer Name
              <span className="text-error ml-2">*</span>
            </h3>
            <input
              type="text"
              value={dataPost.work_orderer}
              onChange={(event) =>
                setDataPost((prevState) => ({
                  ...prevState,
                  work_orderer: event.target.value,
                }))
              }
              readOnly={isReadOnly ? true : undefined}
              className="w-full px-4 py-2 border border-white-100 rounded-[10px] bg-transparent"
              placeholder="Enter work orderer's name"
            />
          </div>

          {/* Work Orderer Email */}
          <div className="mb-8">
            <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-4">
              Work Orderer Email
              <span className="text-error ml-2">*</span>
            </h3>
            <input
              type="email"
              value={dataPost.work_orderer_email}
              onChange={(event) =>
                setDataPost((prevState) => ({
                  ...prevState,
                  work_orderer_email: event.target.value,
                }))
              }
              readOnly={isReadOnly ? true : undefined}
              className="w-full px-4 py-2 border border-white-100 rounded-[10px] bg-transparent"
              placeholder="Enter work orderer's email"
            />
          </div>

          {/* Work Orderer Phone */}
          <div className="mb-8">
            <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-4">
              Work Orderer Phone Number
            </h3>
            <input
              type="tel"
              value={dataPost.work_orderer_phone_no}
              onChange={(event) =>
                setDataPost((prevState) => ({
                  ...prevState,
                  work_orderer_phone_no: event.target.value,
                }))
              }
              readOnly={isReadOnly ? true : undefined}
              className="w-full px-4 py-2 border border-white-100 rounded-[10px] bg-transparent"
              placeholder="Enter work orderer's phone number"
            />
          </div>

          {/* ADD TO WORK QUEUE BUTTON */}
          <button
            className="w-[240px] h-12 md:h-14 bg-gradient rounded-lg text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium"
            onClick={(event) => {
              saveWork();
            }}
          >
            Add to work queue
          </button>
        </div>
      </main>
    </div>
  );
}

export default Addwork;
