import React, { useEffect, useState } from "react";
import Sidebar from "../components/Common/Sidebar";
import Header from "../components/Common/Header";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { vinnActions } from "../state/actions";

function Dashboard({ vinnnoInRequest }) {
  const { t } = useTranslation();
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();

  const [worksData, setWorksData] = useState([]);

  const hideWelcome = () => {
    document.getElementById("welcome-div").style.display = "none";
    localStorage.setItem("hide_welcome", "true");
  };
  const shouldHideWelcome = localStorage.getItem("hide_welcome") === "true";

  async function logout_vin_and_login_function(vinNumb) {
    const userEmail = localStorage.getItem("profile_email");
    const vinnData = JSON.parse(localStorage.getItem("vinnData"));

    if (vinnData && vinnData.data && vinnData.data.vin_code) {
      const vinCode = vinnData.data.vin_code;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/vehicles/logout_vehicle/${userEmail}/?Vehicle_servicing=${vinCode}`
      );

      if (response.ok) {
        const number = { numb: vinNumb };
        await vinnnoInRequest({ number: number, navigate: navigate });
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } else {
      const number = { numb: vinNumb };
      await vinnnoInRequest({ number: number, navigate: navigate });
    }
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/addwork/add-work`)
      .then((response) => {
        setWorksData(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching works.");
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <div className="flex">
        <Sidebar
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
          activeMe="dashboard"
        />

        <Header setOpenSidebar={setOpenSidebar} />

        <main className="min-h-[100vh] md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
          {/* DASHBOARD */}
          <div className="pt-10 pb-[10.4375rem] py-[3.0625rem] xl:py-[3.8125rem] px-[26px] md:px-8 xl:px-[3.4375rem]">
            {!shouldHideWelcome && (
              <div
                id="welcome-div"
                className="bg-black-200 pt-[30px] md:pt-9 pl-5 md:pl-10 xl:pl-[3.4375rem] pr-3 md:pr-8 pb-[5.3125rem] xl:pb-[6.375rem] rounded-[25px]"
              >
                {/* TITLE */}
                <div className="mb-4 md:mb-[38px] flex justify-between items-center">
                  <h1 className="text-f_45_l_57 font-supremeMedium">
                    Welcome {localStorage.getItem("profile")}
                  </h1>
                  <button
                    onClick={hideWelcome}
                    className="text-red-500 text-2xl font-bold hover:text-red-700"
                  >
                    &times;
                  </button>
                </div>

                {/* Learn How to use this System */}
                <div className="mb-[18px] md:mb-8 xl:mb-9">
                  <h3 className="bg-gradient-text text-f_25_l_32 mb-3 md:mb-2.5">
                    {/* Learn How to use this System */}
                    {t("dasboard_learn")}
                  </h3>
                  <p className="text-f_18_l_28">{t("DESC_USE")}</p>
                </div>

                {/* STEPS */}
                <div className="space-y-3 md:space-y-[5px] xl:space-y-[18px] mb-4 md:mb-6 xl:mb-11">
                  {/* STEP 1 */}
                  <div>
                    <h3 className="bg-gradient-text text-f_22_l_28 mb-[5px]">
                      {t("Step_1")}
                    </h3>
                    <p className="text-f_18_l_28">{t("Step_1_desc")}</p>
                  </div>

                  {/* STEP 2 */}
                  <div>
                    <h3 className="bg-gradient-text text-f_22_l_28 mb-[5px]">
                      {t("Step_2")}
                    </h3>
                    <p className="text-f_18_l_28">{t("Step_2_desc")}</p>
                  </div>

                  {/* STEP 3 */}
                  <div>
                    <h3 className="bg-gradient-text text-f_22_l_28 mb-[5px]">
                      {t("Step_3")}
                    </h3>
                    <p className="text-f_18_l_28">{t("Step_3_desc")}</p>
                  </div>
                </div>

                {/* System tutorial videos */}
              </div>
            )}

            <div className="w-full flex flex-row xsm:flex-col justify-center gap-12 xsm:gap-4 pt-16">
              {/* Pending Column */}
              <div className="flex flex-col items-center mb-16">
                <h3 className="text-f_22_l_28 xsm:text-f_18_l_22 uppercase font-bold mb-8">
                  Pending
                </h3>
                <div className="grid grid-cols-1 gap-4 xsm:gap-2">
                  {worksData
                    ?.filter((item) => item.status === 1)
                    .map((item) => (
                      <div
                        key={item.work_id}
                        className="text-wrap break-words px-4 xsm:px-2 h-auto min-h-12 rounded-[10px] border border-white text-f_14_l_18 xsm:text-f_12_l_16 font-supremeMedium uppercase text-center flex items-center justify-center cursor-pointer"
                        onClick={() =>
                          logout_vin_and_login_function(item.vin_code)
                        }
                      >
                        {item.vin_code}
                      </div>
                    ))}
                </div>
              </div>

              {/* Workshop Column */}
              <div className="flex flex-col items-center mb-16">
                <h3 className="text-f_22_l_28 xsm:text-f_18_l_22 uppercase font-bold mb-8">
                  Workshop
                </h3>
                <div className="grid grid-cols-1 gap-4 xsm:gap-2">
                  {worksData
                    ?.filter((item) => item.status === 2)
                    .map((item) => (
                      <div
                        key={item.work_id}
                        className="text-wrap break-words px-4 xsm:px-2 h-auto min-h-12 rounded-[10px] border border-white text-f_14_l_18 xsm:text-f_12_l_16 font-supremeMedium uppercase text-center flex items-center justify-center cursor-pointer"
                        onClick={() =>
                          logout_vin_and_login_function(item.vin_code)
                        }
                      >
                        {item.vin_code}
                      </div>
                    ))}
                </div>
              </div>

              {/* Finished Column */}
              <div className="flex flex-col items-center mb-16">
                <h3 className="text-f_22_l_28 xsm:text-f_18_l_22 uppercase font-bold mb-8">
                  Finished
                </h3>
                <div className="grid grid-cols-1 gap-4 xsm:gap-2">
                  {worksData
                    ?.filter((item) => item.status === 3)
                    .map((item) => (
                      <div
                        key={item.work_id}
                        className="text-wrap break-words px-4 xsm:px-2 h-auto min-h-12 rounded-[10px] border border-white text-f_14_l_18 xsm:text-f_12_l_16 font-supremeMedium uppercase text-center flex items-center justify-center"
                      >
                        {item.vin_code}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    number: state.number,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vinnnoInRequest: (number) => dispatch(vinnActions.vinnInRequest(number)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "Dashboard" })(Dashboard));
