import React, {
    useRef,
    useState,
    useEffect,
    createContext,
    useContext,
  } from "react"
  import { useNavigate } from "react-router-dom";
  import CalendarGfg from "../components/Common/celander"
  import info_icon from "../images/info_icon.svg"
  import SelectService from "../components/Settings/SelectService";
  import Select from "react-tailwindcss-select";
  import SelectSeries from "../components/Settings/SelectSeries";
  import Sidebar from "../components/Common/Sidebar"
  import Header from "../components/Common/Header"
  import { FaEye, FaEyeSlash } from 'react-icons/fa';
  import bike_img from "../images/bike_img.png"
  import placeholder_img from "../images/camera_img.svg"
  import SurveyForm from "../components/Service/Surveyform"
//   import { useNavigate } from "react-router-dom"
  import axios from "axios"
  import Modal from 'react-modal';
  import { ToastContainer, toast } from 'react-toastify';
  import { useTranslation } from "react-i18next";

function Changepassword() {
    const {t}=useTranslation();
    const navigate = useNavigate();
    const [openSidebar, setOpenSidebar] = useState(false);
    const [selectedCards, setSelectedCards] = useState([]);
    const data = localStorage.getItem("vinnData")
    const [showModal, setShowModal] = useState(false);
    const profile_id = localStorage.getItem("profile_id")
    const parsed_data = JSON.parse(data)
    const db_data = parsed_data?.data
    const vin=localStorage.getItem("vinnData")
    const product_id = db_data?.id
    const dateStr = db_data?.date_of_manufacture;
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const dateObj = new Date(dateStr);
    const year = dateObj.getFullYear();
    // const userid=localStorage.getItem("profile_id")
    // console.log("db_data+++++>> ",db_data)
    const image_url = `${db_data?.image}`
    // const [openSidebar, setOpenSidebar] = useState(false)
    
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
  
    const validateForm = () => {
      let hasErrors = false;
  
      if (!oldPassword) {
        toast.error('Old password is required', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        hasErrors = true;
      }
  
      if (!newPassword) {
        toast.error('New password is required', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        hasErrors = true;
      }
  
      return !hasErrors;
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (validateForm()) {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/change-password/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                user_id:profile_id, // Replace with actual user_id
                old_password: oldPassword,
                new_password: newPassword,
              }),
            });
    
            const result = await response.json();
    
            if (response.ok) {
                
              toast.success('Password changed successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              navigate("/dashboard");
            } else {
              if (result.old_password) {
                toast.error(result.old_password[0], {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              } else {
                toast.error('An error occurred', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }
            }
          } catch (error) {
            toast.error('An error occurred', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };
    
    
  return (
    <div className="flex">
    <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

    <Header setOpenSidebar={setOpenSidebar} />

    <main className="md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
      {/* DASHBOARD */}
      <div className="pt-10 pb-[10.4375rem] py-[3.0625rem] xl:py-[3.8125rem] px-[26px] md:px-8 xl:px-[3.4375rem]">
        <div className="bg-black-200 pt-[30px] md:pt-9 pl-5 md:pl-10 xl:pl-[3.4375rem] pr-3 md:pr-8 pb-[5.3125rem] xl:pb-[6.375rem] rounded-[25px]">
        <div className="mb-4 md:mb-[38px] flex justify-center items-center">
  <h1 className="bg-gradient-text text-f_45_l_57 font-supremeMedium">
    Reset Your Password
  </h1>
</div>

          {/* Form Section */}
          <div className="flex items-center justify-center">
            <div className="bg-blue-700 p-6 rounded-lg shadow-lg w-full max-w-[22.1875rem]">
            <form className="w-full flex flex-col space-y-[30px]" onSubmit={handleSubmit}>
      {/* OLD PASSWORD INPUT */}
      <div className="relative w-full">
        <input
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          className="w-full h-[53px] rounded-[10px] border border-white bg-transparent text-center text-white text-f_16_l_20 md:text-f_20_l_25 font-supremeMedium px-3 placeholder:text-white"
          placeholder="old password"
          type={showOldPassword ? "text" : "password"}
        />
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-white cursor-pointer"
          onClick={() => setShowOldPassword(!showOldPassword)}
        >
          {showOldPassword ? <FaEyeSlash /> : <FaEye />}
        </div>
      </div>

      {/* NEW PASSWORD INPUT */}
      <div className="relative w-full">
        <input
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="w-full h-[53px] rounded-[10px] border border-white bg-transparent text-center text-white text-f_16_l_20 md:text-f_20_l_25 font-supremeMedium px-3 placeholder:text-white"
          placeholder="new password"
          type={showNewPassword ? "text" : "password"}
        />
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-white cursor-pointer"
          onClick={() => setShowNewPassword(!showNewPassword)}
        >
          {showNewPassword ? <FaEyeSlash /> : <FaEye />}
        </div>
      </div>

      {/* RESET PASSWORD BUTTON */}
      <button
        type="submit"
        className="bg-gradient w-full h-[53px] text-f_19_l_24 md:text-f_22_l_28 font-supremeMedium rounded-[10px]"
      >
        Reset password
      </button>
    </form>
            </div>
          </div>

        </div>
      </div>
    </main>

  </div>
  )
}

export default Changepassword