import React, { useState } from "react";
import logo from "../images/logo_big.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();  // To get query parameters from the URL
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  // Extract uidb64 and token from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const uidb64 = queryParams.get("uidb64");
  const token = queryParams.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation: both passwords must be provided and match
    if (!newPassword || !confirmNewPassword) {
      toast.error("Both password fields are required.");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/user/password-reset-complete/`,
        {
          password: newPassword,
          uidb64: uidb64,  // Pass uidb64 from URL query
          token: token,  // Pass token from URL query
        }
      );
      toast.success("Your password has been updated successfully.");
      navigate("/");  // Redirect to login page
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || "Something went wrong while resetting your password.");
      } else {
        toast.error("Something went wrong while resetting your password.");
      }
      console.error("Reset Password API Error:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col w-full justify-center items-center px-[26px] md:px-[6.25rem] py-[5.625rem] md:py-[6.625rem]">
        <div className="max-w-[77.5rem] mx-auto w-full bg-black-200 flex flex-col items-center justify-center py-[4.8125rem] md:pt-[10.8125rem] md:pb-[11.875rem] rounded-2xl md:rounded-[25px]">
          {/* LOGO */}
          <div className="w-[226px] md:w-[400px] xl:w-auto mb-14 md:mb-[68px]">
            <img src={logo} alt="logo" />
          </div>

          {/* FORM */}
          <form className="w-full max-w-[22.1875rem] flex flex-col px-[26px] md:px-0 space-y-[30px]">
            {/* NEW PASSWORD INPUT */}
            <input
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full h-[53px] rounded-[10px] border border-white bg-transparent text-center text-white text-f_16_l_20 md:text-f_20_l_25 font-supremeMedium px-3 placeholder:text-white"
              placeholder="New password"
              type="password"
            />

            {/* CONFIRM NEW PASSWORD INPUT */}
            <input
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className="w-full h-[53px] rounded-[10px] border border-white bg-transparent text-center text-white text-f_16_l_20 md:text-f_20_l_25 font-supremeMedium px-3 placeholder:text-white"
              placeholder="Confirm new password"
              type="password"
            />

            {/* RESET PASSWORD BUTTON */}
            <button
              onClick={handleSubmit}
              className="bg-gradient w-full h-[53px] text-f_19_l_24 md:text-f_22_l_28 font-supremeMedium rounded-[10px]"
            >
              Reset Password
            </button>

            {/* BACK TO LOGIN LINK */}
            <div className="text-center mt-4">
              <Link
                to="/"
                className="text-white text-sm hover:text-gray-300"
              >
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
